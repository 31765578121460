import { login, getInfo, logout, loginCode } from "@/api/user";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { get } from "@/api/index";
import router from "@/router";
const state = {
  token: getToken(),
  userInfo: JSON.parse(localStorage.getItem("user-info")),
  showTips: false,
  incomplete: 0,
  packData: null,
  isWebSocket: false,
  stompClient: null,
  isStaging: false
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_SHOW_TIPS: (state, val) => {
    state.showTips = val;
  },
  SET_IN_COMPLETE: (state, val) => {
    state.incomplete = val;
  },
  SET_PACK_DATA: (state, val) => {
    state.packData = val;
  },
  SET_IS_WEBSOCKET: (state, val) => {
    state.isWebSocket = val;
  },
  SET_STOMP_CLIENT: (state, val) => {
    state.stompClient = val;
  },
  SET_STAGING: (state, val) => {
    state.isStaging = val;
  }
};

const actions = {
  login({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      console.log("userInfo", userInfo);
      login(userInfo)
        .then(response => {
          const res = response.data;
          console.log("登录接口", res);
          if (res.token) {
            commit("SET_TOKEN", res.token);
            setToken(res.token);
            if (res.authInfo) {
              commit("SET_USERINFO", res.authInfo);
              commit("SET_SHOW_TIPS", true);
            }
            resolve(res);
          } else reject(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  LoginCode({ commit }, userInfo) {
    console.log("LoginCode", userInfo);
    return new Promise((resolve, reject) => {
      loginCode(userInfo)
        .then(response => {
          const res = response.data;
          if (res.token) {
            commit("SET_TOKEN", res.token);
            setToken(res.token);
            if (res.authInfo) {
              commit("SET_USERINFO", res.authInfo);
              commit("SET_SHOW_TIPS", true);
            }
            resolve(res);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setUserInfo({ commit }, res) {
    return new Promise((resolve, reject) => {
      commit("SET_TOKEN", res.token);
      setToken(res.token);
      if (res.authInfo) {
        commit("SET_USERINFO", res.authInfo);
        commit("SET_SHOW_TIPS", true);
      }
      resolve();
    });
  },
  loginOut({ commit }) {
    return new Promise(resolve => {
      if (getToken()) {
        logout().then(res => {
          router.replace({
            name: "work"
          });
          commit("SET_TOKEN", "");
          commit("SET_USERINFO", null);
          commit("SET_SHOW_TIPS", false);
          removeToken();
          resolve();
          router.replace({
            name: "login"
          });
        });
      } else {
        router.replace({
          name: "work"
        });
        commit("SET_TOKEN", "");
        commit("SET_USERINFO", null);
        removeToken();
        commit("SET_SHOW_TIPS", false);
        resolve();
        router.replace({
          name: "login"
        });
      }
    });
  },
  getUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then(response => {
          const res = response.data;
          commit("SET_USERINFO", res);
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", "");
      commit("SET_USERINFO", null);
      removeToken();
      commit("SET_SHOW_TIPS", false);
      resolve();
      router.replace({
        name: "login"
      });
    });
  },
  getInCompleteNum({ commit }) {
    commit("SET_IN_COMPLETE", 0);
    return new Promise((resolve, reject) => {
      // get(window.configData.VUE_APP_BASEURL + "/sys/feedback/status")
      get(window.configData.VUE_APP_BASEURL + "/cus/feedback/incomplete")
        .then(res => {
          // console.log("呢绒", res);
          // if (res.data) {
          commit("SET_IN_COMPLETE", res.data || 0);
          resolve();
          // }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  setShowTip({ commit }) {
    commit("SET_SHOW_TIPS", false);
  },
  setStaging({ commit }, val) {
    commit("SET_STAGING", val);
  },
  getUserVip({ commit }) {
    commit("SET_PACK_DATA", "");
    return new Promise((resolve, reject) => {
      if (getToken()) {
        get(window.configData.VUE_APP_BASEURL + "/cus/muser-package")
          .then(res => {
            if (res && res.data) {
              commit("SET_PACK_DATA", res.data || "");
              resolve();
            }
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
