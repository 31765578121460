export default Vue => {
  Vue.directive("drag", {
    inserted: function(el, binding, vnode) {
      var odiv = el;
      el.style.cursor = "move";
      odiv.onmousedown = function(e) {
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function(e) {
          let x = e.pageX - disx;
          let y = e.pageY - disy;
          let maxX =
            document.body.clientWidth -
            parseInt(window.getComputedStyle(el).width);
          let maxY =
            document.body.clientHeight -
            parseInt(window.getComputedStyle(el).height);
          if (x < 0) {
            x = 0;
          } else if (x > maxX) {
            x = maxX;
          }

          if (y < 0) {
            y = 0;
          } else if (y > maxY) {
            y = maxY;
          }

          el.style.left = x + "px";
          el.style.top = y + "px";
        };
        document.onmouseup = function() {
          document.onmousemove = document.onmouseup = null;
        };
      };
    }
  });
  Vue.directive("stopdrag", {
    inserted: function(el, binding, vnode) {
      let element = el;
      el.style.cursor = "auto";
      element.onmousedown = function(e) {
        e.stopPropagation();
      };
    }
  });

  Vue.directive('disable-contextmenu', {
    bind(el) {
      el.oncontextmenu = function(e) {
        e.preventDefault();
      };
    },
    unbind(el) {
      el.oncontextmenu = null;
    }
  });

  Vue.directive('double-input', {
    // 当绑定元素插入到DOM中
    inserted: function (el) {
      // 为el-input绑定input事件监听
      el.$onInput = function (event) {
        // 获取输入框的当前值
        let value = event.target.value;
        // 使用正则表达式来判断并清理非数字字符
        value = value.replace(/[^0-9.]/g, '');
        // 设置输入框的值
        event.target.value = value;
      };
      el.addEventListener('input', el.$onInput);
    },
    // 当绑定元素从DOM中移除
    unbind: function (el) {
      // 移除事件监听
      el.removeEventListener('input', el.$onInput);
      delete el.$onInput;
    }
  });

  Vue.directive('int-input', {
    // 当绑定元素插入到DOM中
    inserted: function (el) {
      // 为el-input绑定input事件监听
      el.$onInput = function (event) {
        // 获取输入框的当前值
        let value = event.target.value;
        // 使用正则表达式来判断并清理非数字字符
        value = value.replace(/[^0-9]/g, '');
        // 设置输入框的值
        event.target.value = value;
      };
      el.addEventListener('input', el.$onInput);
    },
    // 当绑定元素从DOM中移除
    unbind: function (el) {
      // 移除事件监听
      el.removeEventListener('input', el.$onInput);
      delete el.$onInput;
    }
  });
};
