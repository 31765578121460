const config = {
  tokenCookieExpires: 1,

  passCookieExpires: 1,

  uniqueOpened: true,

  TokenKey: "JK-TOEKN",

  timeout: 1200000,

  tagsView: true,

  fixedHeader: false,

  sidebarLogo: true,
  settingBtn: false,

  btnSize: "medium",
  snapshot: require("images/home/work.png")
};

export default config;
