import Vue from "vue";
import Router from "vue-router";

let originPush = Router.prototype.push; //备份原push方法

Router.prototype.push = function(location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
let originReplace = Router.prototype.replace; //备份原push方法

Router.prototype.replace = function(location, resolve, reject) {
  if (resolve && reject) {
    //如果传了回调函数，直接使用
    originReplace.call(this, location, resolve, reject);
  } else {
    //如果没有传回调函数，手动添加
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/home",
      name: "home",
      component: resolve => require(["@/views/home"], resolve),
      meta: { title: "首页" }
    },
    {
      path: "/",
      name: "work",
      redirect: "/staging",
      component: resolve => require(["@/components/home"], resolve),
      children: [
        {
          path: "/staging",
          name: "staging",
          component: resolve => require(["@/views/staging"], resolve),
          meta: { title: "工作台" }
        },

        {
          path: "/member",
          name: "member",
          component: resolve => require(["@/views/member"], resolve),
          meta: { title: "会员购买" }
        },
        {
          path: "/userCenter",
          name: "userCenter",
          component: resolve => require(["@/views/user/index"], resolve),
          meta: { title: "个人中心" }
        },
        {
          path: "/message",
          name: "message",
          component: resolve => require(["@/views/message/index"], resolve),
          meta: { title: "消息中心" }
        },
        {
          path: "/msgDetail",
          name: "msgDetail",
          component: resolve => require(["@/views/message/detail"], resolve),
          meta: { title: "消息详情" }
        },
        {
          path: "/operation",
          name: "operation",
          component: resolve => require(["@/views/operation"], resolve),
          meta: { title: "帮助中心" }
        }
      ]
    },
    {
      path: "/share",
      name: "share",
      component: resolve => require(["@/views/share"], resolve),
      meta: { title: "share" }
    },
    {
      path: "/cad",
      name: "cad",
      component: resolve => require(["@/views/cad"], resolve),
      meta: { title: "cad" }
    },
    {
      path: "/login",
      name: "login",
      component: resolve => require(["@/views/login"], resolve),
      meta: { title: "登录" }
    },
    {
      path: "/register",
      name: "register",
      component: resolve => require(["@/views/register"], resolve),
      meta: { title: "注册" }
    },
    {
      path: "/forgetPwd",
      name: "forgetPwd",
      component: resolve => require(["@/views/forgetPwd"], resolve),
      meta: { title: "忘记密码" }
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      path: "/404",
      name: "404",
      component: () => import("@/components/common/404")
    },
    {
      path: "/redirect",
      component: resolve => require(["@/components/home"], resolve),
      hidden: true,
      children: [
        {
          path: "/redirect/:path*",
          component: () => import("@/views/redirect/index")
        }
      ]
    }
  ]
});
