<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import store from "@/store";
import { webSocket } from "@/utils/webSocket";
import { getToken } from "@/utils/auth";
export default {
  mixins: [webSocket],
  data() {
    return {};
  },
  created() {
    if (getToken()) store.dispatch("user/getUserVip");
  },
  mounted() {
    this.openServer();
  },
  methods: {},
  watch: {
    $route(newValue, oldValue) {
      store.dispatch("sys/setRouterName", newValue.name);
    }
  }
};
</script>
<style lang="stylus" scoped>


#app
  font-family PingFang SC-Medium, PingFang SC
  font-size 14px
  color #222
  font-weight 500
</style>
