var stompClients = null;
var SockJS = window.SockJS;
var Stomp = window.Stomp;
var _this = null;
import { mapGetters } from "vuex";
var serverUrl = window.configData.VUE_APP_BASEURL; //"http://dendralink.com:8085";
export const webSocket = {
  data() {
    return {
      isSend: false,
      sendData: null,
      isWebSocket: false,
      isMsgDetail: false
    };
  },
  components: {},
  computed: {
    ...mapGetters(["userInfo"]),
    username() {
      return (this.userInfo && this.userInfo.nickName) || "";
    },
    senderUserId() {
      return (
        (this.userInfo && (this.userInfo.userId || this.userInfo.id)) || ""
      );
    },
    web_token() {
      return this.$getToken || "";
    }
  },
  methods: {
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.mianscroll;
        scrollElem.scrollTo({
          top: scrollElem.scrollHeight,
          behavior: "smooth"
        });
      });
    },
    openServer() {
      _this = this;

      if (_this.senderUserId && this.web_token && !this.isWebSocket)
        this.connect();
    },

    // 创建连接
    connect() {
      _this = this;
      stompClients = null;
      if (this.username) {
        //连接到 websocket 服务
        var socket = new SockJS(serverUrl + "/ws");
        stompClients = Stomp.over(socket);

        // 连接时带上token， username：用户名  platform：平台端：manager 用户端：customer
        stompClients.connect(
          {
            Authorization: "Bearer " + this.$getToken,
            username: _this.username,
            platform: "customer"
          },
          _this.onConnected,
          _this.onError
        );

        //订阅获取聊天记录,连线到服务器返回聊天记录 ， 暂时用不上
        //   stompClient.subscribe(
        //     "/topic/private/history/customer:" + senderUserId,
        //     onReceivedHistotryMessage
        //   );
      }
    },
    // 连接成功
    onConnected() {
      //用户端customer 订阅自己的私信
      stompClients.subscribe(
        "/topic/private/customer:" + _this.senderUserId,
        _this.onMessageReceived
      );
      // 平台端manager 订阅自己的私信
      // stompClients.subscribe(
      //   "/topic/private/manager:" + _this.senderUserId,
      //   _this.onMessageReceived
      // );
      stompClients.send(
        "/app/chat.addUser",
        {},
        JSON.stringify({ sender: _this.username, type: "JOIN" })
      );

      this.isWebSocket = true;
      if (this.isSend && this.sendData) this.sendMessage(this.sendData);
    },
    // 连接失败
    onError(error) {
      console.log("onError", error);
    },
    // 发送消息
    sendMessage(messageInput) {
      this.isSend = true;
      this.sendData = messageInput;
      console.log(
        "stompClient",
        this.isWebSocket,
        stompClients && stompClients.send
      );
      if (
        !this.isWebSocket ||
        !stompClients ||
        (stompClients && !stompClients.send)
      ) {
        this.connect();
      }
      // if (!this.isWebSocket) {
      //   alert("请先点接连接");
      // }

      // getPlatform();
      if (messageInput && stompClients && messageInput.feedbackId) {
        var chatMessage = {
          sender: this.username, //用户名
          senderUserId: this.senderUserId, //发送者id
          receiverUserId: messageInput.recipientUserId, //接收者id
          content: messageInput.content, //信息内容
          feedbackId: messageInput.feedbackId, //反馈id，对应反馈条目
          senderPlatform: "customer", //发送者平台 平台端：manager 用户端：customer
          receiverPlatform: "manager", //接受者平台 平台端：manager 用户端：customer
          type: "CHAT" //消息类型
        };

        var url = "/app/chat.sendMessage"; //发送广播消息
        if (messageInput.recipientUserId != null) {
          url = "/app/chat.sendMessage.private"; //发私信
          // appendSelfMessage(messageInput.value) //添加消息到界面
        }
        this.sendData = null;
        this.isSend = false;
        stompClients.send(url, {}, JSON.stringify(chatMessage)); //发送消息
        this.pusMsg({
          sender: {
            nickName: this.username
          },
          content: messageInput.content,
          sendTime: new Date()
        });
      }
    },
    // 获取聊天信息
    onMessageReceived(payload) {
      var message = JSON.parse(payload.body);
      console.log("onMessageReceived", message);
      if (message.feedbackId) {
        this.$store.dispatch("user/getInCompleteNum");
        if (this.isMsgDetail) this.getHistoryData(message.feedbackId);
        // this.markMessageAsRead(message);
      }
    },
    // 消息为已读
    markMessageAsRead(messageInput) {
      if (messageInput && messageInput.feedbackId) {
        stompClients.send(
          "/app/message.mark.read",
          {},
          JSON.stringify({
            receiverUserId: messageInput.recipientUserId,
            senderUserId: this.senderUserId,
            feedbackId: messageInput.feedbackId
          })
        );
      }
    },
    // 获取消息列表
    onReceivedHistotryMessage(payload) {
      console.log("onReceivedHistotryMessage", payload);
    }
  },
  deactivated() {}
};
