import { get, post, put, del } from "@/api/index";
// 判断手机号是否已注册
export function getPhoneExist(phone) {
  return get("/auth/phone/exist/" + phone);
}
// 发送验证码
export function sendPhone(phone) {
  return post("/auth/phone/send/" + phone);
}
// 发送邮箱验证码
export function sendEmail(email) {
  return post("/auth/email/send/" + email);
}

// 注册
export function register(data) {
  return post("/auth/phone/reg", data);
}
// 登录
export function login(data) {
  let url = "/auth/customer";
  let datas = {};
  if (data.isPhone) {
    if(data.isPhonePassword===true)
    {
      url = "/auth/phone/pwd";
      datas = {
        password: data.password,
      };
    }
    else
    {
      url = "/auth/phone/code";
      datas = {
        code: data.code,
        state: "",
        thirdChannel: ""
      };
    }
    if (data.isEmail) {
      datas.email = data.phone;
      url = "/auth/email/code";
    } else datas.phone = data.phone;
  } else {
    datas = {
      password: data.password,
      username: data.username,
      validatePassword: true
    };
  }

  return post(url, datas);
}
export function loginCode(data) {
  let url = "/auth/qq";
  if (data.state == "WX" || data.state == "wx") url = "/auth/wechat";
  return post(url, data);
}
// 获取用户信息
export function getInfo() {
  return get(window.configData.VUE_APP_BASEURL + "/cus/user/info");
}
// 退出登录
export function logout() {
  return del("/auth/logout");
}
// 修改用户密码-手机号码
export function editPsw(data) {
  return put("/auth/phone/edit-pwd", data);
}
// 修改用户密码-邮箱
export function editPswEmail(data) {
  return put("/auth/email/reset", data);
}
