const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  collapse: state => state.sys.collapse,
  routerName: state => state.sys.routerName,
  incomplete: state => state.user.incomplete,
  showTips: state => state.user.showTips,
  packData: state => state.user.packData,
  isWebSocket: state => state.user.isWebSocket,
  stompClient: state => state.user.stompClient,
  isStaging: state => state.user.isStaging
};
export default getters;
