import store from "@/store";
function checkArray(permissionRoles) {
  const roles =  store.getters && store.getters.roles ? store.getters.roles : ["ADMIN"];
  console.log(
    "roles",
    roles,
    roles.length,
    isArray(roles),
    permissionRoles,
    isArray(permissionRoles)
  );
  if (isArray(roles) && roles.length > 0) {
    if (!isArray(permissionRoles)) {
      let index = roles.indexOf(permissionRoles);
     
      return index > -1;
    } else {
      const hasPermission = roles.some(role => {
        return permissionRoles.includes(role);
      });
      console.log("hasPermission", hasPermission);
      return hasPermission;
    }
  }
  return false;
}
function isArray(arr) {
  if (!Array.isArray) {
    return Object.prototype.toString.call(arr) === "[object,Array]";
  }
  return Array.isArray(arr);
}
const permission = {
  inserted: function(el, binding) {
    let permission = binding.value; // 获取到 v-permission的值
    if (permission) {
      let hasPermission = checkArray(permission);
      if (!hasPermission) {
        // 没有权限 移除Dom元素
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  }
};

export default permission;
