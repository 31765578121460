import {
  Image,
  Icon,
  Popover,
  Menu,
  Submenu,
  MenuItem,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  DatePicker,
  drawer,
  Timeline,
  TimelineItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  slider,
  divider,
  Tooltip,
  Dialog,
  Form,
  FormItem,
  Input,
  Pagination,
  Select,
  Radio,
  RadioGroup,
  Upload,
  Card,
  Row,
  Col,
  Tag,
  Option,
  OptionGroup,
  Checkbox,
  Badge,
  Scrollbar,
  Cascader,
  CheckboxGroup,
  InputNumber,
  TabPane,
  Tabs,
  Switch,
  MenuItemGroup,
  Progress
} from "element-ui";

const componentList = [
  Image,
  Icon,
  Popover,
  Menu,
  Submenu,
  MenuItem,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  DatePicker,
  drawer,
  Timeline,
  TimelineItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  slider,
  divider,
  Tooltip,
  Dialog,
  Form,
  FormItem,
  Input,
  Pagination,
  Select,
  Radio,
  RadioGroup,
  Switch,
  Upload,
  Card,
  Row,
  Col,
  Tag,
  Option,
  OptionGroup,
  Checkbox,
  Badge,
  Scrollbar,
  Cascader,
  CheckboxGroup,
  InputNumber,
  TabPane,
  Tabs,
  MenuItemGroup,
  Progress
];
export function registerElement(app) {
  componentList.forEach(comp => {
    app.use(comp);
  });
}
