// const directives = {
//     copy,
//     longpress,
//   }
const modulesFiles = require.context("./modules", false, /\.js$/);

const directives = modulesFiles.keys().reduce((directives, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  directives[moduleName] = value.default;
  return directives;
}, {});
export default {
  install(Vue) {
    Object.keys(directives).forEach(key => {
      Vue.directive(key, directives[key]);
    });
  }
};
