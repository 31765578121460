import bus from "./bus";
import { getToken } from "@/utils/auth";
import { formatDate } from "./parseTime";
import { funGetDate } from "./funGetDate";
import { getImgUrl } from "./getImgUrl";
import { getUUId } from "./getUUId";
import { isvalidPhone, validateForm } from "./validateForm";
import config from "./config";
import axios from "axios";
// 返回用户姓名
function getUserName(name) {
  return name ? name : "未登录用户";
}

// 设置API
function setApi(url) {
  return window.configData.VUE_APP_BASEURL + url;
}
function getFileType(fileName) {
  // 后缀获取
  var suffix = "";
  // 获取类型结果
  var result = "";
  try {
    var flieArr = fileName.split(".");
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = "";
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
  // 进行图片匹配
  result = imglist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "image";
    return result;
  }
  // 匹配txt
  var txtlist = ["txt"];
  result = txtlist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "txt";
    return result;
  }
  // 匹配 excel
  var excelist = ["xls", "xlsx"];
  result = excelist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "excel";
    return result;
  }
  // 匹配 word
  var wordlist = ["doc", "docx"];
  result = wordlist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "word";
    return result;
  }
  // 匹配 pdf
  var pdflist = ["pdf"];
  result = pdflist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "pdf";
    return result;
  }
  // 匹配 ppt
  var pptlist = ["ppt"];
  result = pptlist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "ppt";
    return result;
  }
  // 匹配 视频
  var videolist = ["mp4", "m2v", "mkv"];
  result = videolist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "video";
    return result;
  }
  // 匹配 音频
  var radiolist = ["mp3", "wav", "wmv"];
  result = radiolist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "radio";
    return result;
  }
  // 匹配 压缩包
  var wordlist = ["zip", "rar", "tar", "gz", "7z"];
  result = wordlist.some(function(item) {
    return item == suffix;
  });
  if (result) {
    result = "package";
    return result;
  }
  // 其他 文件类型
  result = "other";
  return result;
}
function setNull(val, text = "") {
  if (val && val != "null" && val != "undefined") return val;
  else return text;
}
function downFile(data) {
  return new Promise((resolve, reject) => {
    axios({
      url: window.configData.VUE_APP_BASEURL + "/file/download/id/" + data.id, // 请求地址
      method: "get",
      headers: {
        Authorization: "Bearer " + getToken()
      },
      responseType: "blob" // 表明返回服务器返回的数据类型
    })
      .then(res => {
      
        if (res.data) {
          let url = window.URL.createObjectURL(res.data);
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", data.name);
          document.body.appendChild(link);
          link.click();
          resolve();
        }
      })
      .catch(err => {
       
        reject(err);
      });
  });
}
function returnFileImg(fileName) {
  let type = getFileType(fileName);
  if (type == "package" || type == "radio" || type == "video") type = "other";
  return require(`@/assets/images/fileIcon/icon_${type}.png`);
}
// 返回属性类型属性字段value值
function returnTypeName(name, geometry) {
  let value = "";
  if (!geometry || !geometry._entity_data) return "";
  switch (name) {
    case "text":
      value = geometry._entity_data._text || "";
      break;
    case "textfont":
      value = geometry._entity_data._fontname || "";
      break;
    case "theight":
      value = geometry._entity_data._textheight || "";
      break;
    case "rotate":
      value = Math.round((geometry._entity_data._rotate * 180) / Math.PI) || 0;
      break;
    case "position.x":
      value = geometry._entity_data.position.x || "";
      break;
    case "position.y":
      value = geometry._entity_data.position.y || "";
      break;
    case "radius":
      value = geometry._entity_data._radius || "";
      break;
    case "center.x":
      value = geometry._entity_data._center.x || "";
      break;
    case "center.y":
      value = geometry._entity_data._center.y || "";
      break;
    case "scale":
      value = geometry._entity_data._scale || "";
      break;
    case "xscale":
      value = geometry._entity_data.scaleFactors.x || 0;
      break;
    case "yscale":
      value = geometry._entity_data.scaleFactors.y || 0;
      break;
    case "color":
      value = geometry._entity_data._color || "";
      break;
    case "_color":
      value = geometry._color || "";
      break;
    case "rtext._height":
      value = geometry._entity_data._rtext._textheight || "";
      break;
    case "_rtext.position.x":
      value = geometry._entity_data._rtext.position.x || "";
      break;
    case "_rtext.position.y":
      value = geometry._entity_data._rtext.position.y || "";
      break;
    case "_rtext._text":
      value = geometry._entity_data._rtext._text || "";
      break;

    case "columnCount":
      value = geometry._entity_data.columnCount || 0;
      break;
    case "columnSpacing":
      value = geometry._entity_data.columnSpacing || 0;
      break;
    case "rowCount":
      value = geometry._entity_data.rowCount || 0;
      break;
    case "rowSpacing":
      value = geometry._entity_data.rowSpacing || 0;
      break;
    case "_majorPoint.x":
      value = geometry._entity_data._majorPoint.x || 0;
      break;
    case "_majorPoint.y":
      value = geometry._entity_data._majorPoint.y || 0;
      break;
    case "_ratio":
      value = geometry._entity_data._ratio || 0;
      break;
    case "_style":
      value = geometry._entity_data._style || "";
      break;
    case "_startParam":
      value =
        Math.round((geometry._entity_data._startParam * 180) / Math.PI) || 0;
      break;
    case "_endParam":
      value =
        Math.round((geometry._entity_data._endParam * 180) / Math.PI) || 0;
      break;
    case "_start_angle":
      value =
        Math.round((geometry._entity_data._start_angle * 180) / Math.PI) || 0;
      break;
    case "_end_angle":
      value =
        Math.round((geometry._entity_data._end_angle * 180) / Math.PI) || 0;
      break;
    case "center.x":
      value = geometry._entity_data._center.x || "";
      break;
    case "center.y":
      value = geometry._entity_data._center.y || "";
      break;
    case "_start_point.x":
      value = geometry._entity_data._start_point.x || "";
      break;
    case "_start_point.y":
      value = geometry._entity_data._start_point.y || "";
      break;
    case "_end_point.x":
      value = geometry._entity_data._end_point.x || "";
      break;
    case "_end_point.y":
      value = geometry._entity_data._end_point.y || "";
      break;
    case "_arcLength":
      value = geometry._entity_data.getAngleLength()
        ? Number(geometry._entity_data.getAngleLength()).toFixed(2)
        : "";
      break;
    case "blockName":
      value = geometry._entity_data.blockName || "";
  }
  return value;
}
function handleScroll() {
  let doms = document.getElementsByClassName("el-select-dropdown");
  for (const key in doms) {
    if (Object.hasOwnProperty.call(doms, key)) {
      const element = doms[key];
      if (element.style.display == "") {
        // element.style.display = "none";  
        element.style = 'min-width: 250px; transform-origin: center top; z-index: 2022; display: none;'
      }
    }
  }
}
// 获取颜色数组是否重复
function hasDuplicateObject(arr) {
  const jsonStrings = new Set();
  for (const item of arr) {
    const jsonString = JSON.stringify(item);
    if (jsonStrings.has(jsonString)) {
      return true; // 存在完全相同的对象
    }
    jsonStrings.add(jsonString);
  }
  return false; // 不存在完全相同的对象
}
function getRepeatColorArr(arr = []) {
  if (arr) {
    if (arr.length == 1) return arr[0];
    else {
      if (!hasDuplicateObject(arr))
        return {
          value: "多种",
          color: "多种",
          index: -2
        };
      else {
        return {
          value: arr[0].value,
          val: arr[0].val,
          index: arr[0].index
        };
      }
    }
  }
}
// 获取数组是否重复
function getRepeatArr(arr = []) {
  if (arr.length >= 2 && Array.from(new Set(arr)).length > 1) {
    return "多种";
  } else return arr[0] || "";
}
//10进制转16进制
function rgbTenToSix(color) {
  return color.replace(/0x/gi, "#");
}
// 16进制转10进制
function rgbSixToTen(color) {
  return color.replace(/#/gi, "0x");
}
// 判断颜色值是否相等
function judgeColor(color, color1) {
  if (color.toUpperCase() == color1.toUpperCase()) return true;
  else return false;
}

const custom = {
  bus,
  config,
  setApi,
  formatDate,
  funGetDate,
  getImgUrl,
  getUUId,
  isvalidPhone,
  validateForm,
  getUserName,
  getFileType,
  downFile,
  axios,
  setNull,
  returnFileImg,
  returnTypeName,
  handleScroll,
  getRepeatColorArr,
  getRepeatArr,
  rgbTenToSix,
  rgbSixToTen,
  judgeColor
};

export default custom;
