export function getImgUrl(v) {
  console.log("getImgUrl", v);
  if (v != null) {
    // let http = /^http[s]{0,1}:\/\/.*/i.test(v);
    const reg = new RegExp("^(http|https)://", "i");
    const http = reg.test(v);
    if (http) return v;
    else return process.env.VUE_APP_BASE_API_IMG + v;
  }
  return require("@/assets/images/wutu.png");
}
