import { getRole } from "@/api/user";

const state = {
  collapse: false, //是否展开侧栏
  role: "", //角色权限
  routerName: "home"
};

const mutations = {
  SET_COLLAPSE: (state, collapse) => {
    state.collapse = collapse;
  },
  SET_ROUTERNAME: (state, v) => {
    state.routerName = v;
  }
};

const actions = {
  setCollapse({ commit }, collapse) {
    commit("SET_COLLAPSE", collapse);
  },
  setRouterName({ commit }, routerName) {
    commit("SET_ROUTERNAME", routerName);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
