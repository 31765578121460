function funGetDate(count) {
  var dd = new Date();
  dd.setDate(dd.getDate() + count); //获取p_count天后的日期
  //   var y = dd.getFullYear()
  //   var m = dd.getMonth() + 1 //获取当前月份的日期
  //   var d = dd.getDate()
  return dd;
}
module.exports = {
  funGetDate
};
