// 判断表单的必填项
export function validateForm(call, type = "form") {
  const promises = [this.$refs[type]];
  let count = 0;
  for (let index = 0; index < promises.length; index++) {
    const element = promises[index];
    element.validate((result, errors) => {
      if (result) {
        count++;
        if (count == promises.length) {
          call(true);
          return;
        }
      } else {
        const fkey = Object.keys(errors)[0];
        call(false, errors[fkey]);
        return;
      }
    });
  }
}
// 判断手机号是否规范
export function isvalidPhone(str) {
  const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
  return reg.test(str);
}
// formdata去null值处理
export function formDataNullProcess(formData) {
  const editformData = new FormData();
  // value是值，key是属性名
  formData.forEach((value, key) => {
    // 值是空的就跳过，不是就赋值到新的formdata
    if (value !== null && typeof value !== "undefined" && value !== "null") {
      // if (value !== null && value !== 'null') {
      editformData.append(key, value);
    }
  });
  return editformData;
}
