import { mapGetters } from "vuex";
export const roleMixin = {
  computed: {
    ...mapGetters(["packData"]),
    isUserVip() {
      let isTrue = false;
      if (this.judePackType()) {
        isTrue = this.packData.packageSimple.packageType.indexOf("FREE") == -1;
      }
      return isTrue;
    },
    isEditCad() {
      return this.getPackageSimpleByVal("feaCadOnlineEdit") && this.$getToken;
    }
  },
  data() {
    return {
      // userAvatar: require("images/avatar.png")
    };
  },
  methods: {
    judePackType() {
      return this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple.packageType
        ? this.packData.packageSimple.packageType
        : "";
    },
    getPackageSimpleByVal(str) {
      if (!this.packData) return false;
      if (
        this.packData &&
        this.packData.packageSimple &&
        this.packData.packageSimple[str]
      ) {
        if (
          this.packData.packageSimple[str] != -200 &&
          this.packData.packageSimple[str] != "false"
        )
          return true;
      } else return false;
    }
  }
};
